<template>
  <div>
    <div
      v-if="sessionId"
      class="w-5/6 justify-center items-center mx-auto my-4"
    >
      <div class="my-3">
        <span
          class="bg-gray-100 text-gray-800 text-base font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"
          >{{ chosenPlaybook.name }}</span
        >
      </div>

      <LayerOne
        :l1-load-type="this.l1LoadType"
        :initial-data="this.initialData"
        :session-data="this.sessionData"
        :layers="this.layers"
        :layers-display="this.layersDisplay"
        :chosen-playbook="this.chosenPlaybook"
      />
      <div></div>
    </div>
  </div>
</template>

<script>
import LayerOne from "@/components/FourLayered/LayerOne.vue";

export default {
  inject: ["eventBus"],
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    initialData: {
      type: String,
      required: true,
    },
    sessionType: {
      type: String,
      required: true,
    },
    sessionData: {
      type: Object,
      required: false,
    },
    layerData: {
      type: Object,
      required: false,
    },
    chosenPlaybook: {
      type: Object,
      required: false,
    },
  },
  components: {
    LayerOne,
  },
  data() {
    return {
      l1LoadType: "",
      itemResponse: null,
      layers: [],
      layersDisplay: [],
    };
  },
  created() {
    this.layers = this.layerData.layers.map((layer) => layer.name);
    this.layersDisplay = this.layerData.layers.map(
      (layer) => layer.display_name
    );
    if (this.sessionType == "retrival") {
      this.l1LoadType = "retrieve";
      this.startSessionRetrival();
    } else if (this.sessionType == "new") {
      this.l1LoadType = "generate";
      this.startNewSession();
    }
  },
  methods: {
    async startNewSession() {
      // window.alert("New session started");
    },
    startSessionRetrival() {
      // window.alert("Session retrived");
    },
  },
};
</script>
