<template>
  <fieldset
    v-if="!disabled"
    class="grid grid-cols-2 gap-6 mb-3 border border-gray-200 rounded-lg p-5 relative"
  >
    <div
      class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4 font-medium text-gray-900 dark:text-white"
    >
      Choose an existing idea
    </div>
    <div class="col-span-2 text-center">
      <select
        v-model="selectedProductId"
        id="products"
        @change="onProductChange"
        class="mx-auto bg-slate-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-1/2"
      >
        <option :value="null" selected>Choose a product idea</option>
        <option v-for="idea in ideasOptions" :key="idea.id" :value="idea.id">
          {{ idea.idea }}
        </option>
      </select>
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    ideasOptions: Array,
    disabled: Boolean,
  },
  data() {
    return {
      selectedIdeaId: "",
    };
  },
  methods: {
    onProductChange() {
      // Emit the 'selected-product' event with the selected product's details
      this.$emit("selected-product", this.selectedProductId);
    },
  },
};
</script>
