<template>
  <div class="mx-auto">
    <div>
      <HeaderLogo class="lg:mx-32 md:mx-16 my-1" />
      <hr />
    </div>
    <div class="flex justify-center">
      <ListPlaybooks :playbook-data="this.chosenPlaybook" />
    </div>
    <PageLoading class="mx-8 pl-20" v-if="this.isLoading" />
    <div v-if="this.playbookInfo != null">
      <InitialQuestionnaire
        :questionAndAnswers="this.playbookInfo.initial_questionnaires"
        :projects="this.projects"
        :disabled="false"
      />
    </div>
  </div>
</template>
<script>
import { useAuth } from "vue-clerk";
import { useRouter } from "vue-router";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ListPlaybooks from "@/components/ListPlaybooks.vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
import PageLoading from "@/components/PageLoading.vue";
import InitialQuestionnaire from "@/components/InitialQuestionnaire.vue";

export default {
  inject: ["eventBus"],
  data() {
    return {
      userId: "",
      initial_questionnaire: null,
      chosenPlaybook: "",
      playbookInfo: null,
      isLoading: false,
      projects: [],
    };
  },
  components: {
    InitialQuestionnaire,
    ListPlaybooks,
    HeaderLogo,
    PageLoading,
  },
  created() {
    this.getUserDetails();
    this.eventBus.on("thread-start", (data) => {
      this.initial_questionnaire = data;
    });
    this.eventBus.on("user-chosen-playbook", (data) => {
      this.playbookInfo = null;
      this.chosenPlaybook = data;
      sessionStorage.setItem(
        "chosenPlaybook",
        JSON.stringify(this.chosenPlaybook)
      );
      this.getPlaybookAndProjectInfo();
    });
    this.eventBus.on("initial-questionnaire-response", (data) => {
      sessionStorage.setItem("questionnaireResponse", JSON.stringify(data));
      this.createSessionId();
    });
  },
  beforeUnmount() {
    this.eventBus.off("thread-start");
    this.eventBus.off("user-chosen-playbook");
    this.eventBus.off("initial-questionnaire-response");
  },
  methods: {
    getUserDetails() {
      const { userId } = useAuth();
      const router = useRouter();
      if (!userId.value) {
        router.push("/sign-in");
      } else {
        this.userId = userId.value;
      }
    },
    async fetchData(url, params) {
      try {
        const response = await axios.get(url, {
          params: params,
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },

    async getPlaybookAndProjectInfo() {
      this.isLoading = true;
      try {
        const playbookPromise = this.fetchData("pcp-api/s3-contents", {
          bucket_name: process.env.VUE_APP_PLAYBOOKS_SOURCE,
          object_key: `${this.chosenPlaybook.s3_file_key}`,
        });
        const projectPromise = this.fetchData("pcp-api/utility", {
          user_id: this.userId,
          type: "user.projects",
        });

        const [playbookInfo, projects] = await Promise.all([
          playbookPromise,
          projectPromise,
        ]);

        this.playbookInfo = playbookInfo;
        this.projects = projects;
      } finally {
        this.isLoading = false;
      }
    },
    createSessionId() {
      const sessionId = uuidv4();
      sessionStorage.setItem("sessionId", sessionId);
      this.$router.push({ name: "session", query: { id: sessionId } });
    },
  },
};
</script>
