<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center"
  >
    <div class="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
      <h2 class="text-xl font-bold mb-4">Create New Project</h2>
      <form @submit.prevent="saveProject">
        <div class="mb-4">
          <label
            for="projectName"
            class="block text-sm font-medium text-gray-700"
            >Project Name</label
          >
          <input
            v-model="projectName"
            type="text"
            id="projectName"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div v-for="(question, index) in questions" :key="index" class="mb-4">
          <label
            :for="'question' + index"
            class="block text-sm font-medium text-gray-700"
            >{{ question }}</label
          >
          <textarea
            v-model="answers[index]"
            :id="'question' + index"
            rows="3"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          ></textarea>
        </div>

        <div class="flex justify-end space-x-2">
          <button
            @click="closeModal"
            type="button"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700"
          >
            Save Project
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateProjectModal",
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      projectName: "",
      questions: [
        "What is the core problem or challenge you're trying to address?",
        "How would you describe your project in one sentence?",
        "Who experiences this problem and how frequently?",
        "What are the primary benefits or value propositions of your idea?",
      ],
      answers: ["", "", "", ""],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveProject() {
      // Here you would typically send the data to your backend
      const projectData = {
        name: this.projectName,
        answers: this.answers,
      };
      console.log("Saving project:", projectData);
      // After saving, close the modal and maybe refresh the project list
      this.closeModal();
    },
  },
};
</script>
