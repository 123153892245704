<template>
  <SpeedInsights />
  <ClerkLoaded>
    <router-view class="font-poppins" />
  </ClerkLoaded>
</template>

<script>
// vercel insights
import { SpeedInsights } from "@vercel/speed-insights/vue";
import { ClerkLoaded } from "vue-clerk";

export default {
  components: {
    ClerkLoaded,
    SpeedInsights,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
