<template>
  <velt-comments></velt-comments>
  <velt-comments-sidebar pageMode="true"></velt-comments-sidebar>
  <!--PlaybookStepper /-->
  <div class="w-5/6 justify-center items-center mx-auto">
    <form>
      <fieldset
        class="flex flex-col items-center mb-6 border border-gray-200 rounded-lg p-8 relative"
      >
        <div
          v-if="disabled == false"
          class="absolute text-m top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4 text-md text-gray-900 dark:text-white"
        >
          <h3 class="text-md font-medium text-gray-900 dark:text-white">
            Session Name
          </h3>
        </div>
        <div class="w-1/2 mb-6">
          <input
            v-model="sessionName"
            :disabled="disabled"
            type="text"
            class="bg-slate-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </fieldset>

      <!-- <fieldset
        v-if="!disabled"
        class="grid grid-cols-2 gap-6 mb-6 border border-gray-200 rounded-lg p-5 relative"
      >

        <div
          class="absolute text-m top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4 text-md font-medium text-gray-900 dark:text-white"
        >
          Choose Project
        </div>
        <div class="col-span-2 text-left relative">
          <input
            v-model="searchTerm"
            @focus="showOptions = true"
            @blur="closeDropdown"
            @input="filterProjects"
            :disabled="disabled"
            type="text"
            class="bg-slate-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 mx-auto"
          />
          <div
            v-if="showOptions"
            class="absolute text-sm top-full z-10 bg-white border border-gray-100 mt-1 rounded-lg w-1/2 left-1/2 transform -translate-x-1/2"
          >
            <ul>
              <li
                v-for="project in filteredProjects"
                :key="project.name"
                @click="handleSelection(project.name)"
                class="px-3 py-2 cursor-pointer hover:bg-slate-100"
              >
                {{ project.name }}
              </li>
              <li
                v-if="!filteredProjects.length && searchTerm.trim() !== ''"
                @click="handleNewProject(searchTerm)"
                class="px-3 py-2 cursor-pointer hover:bg-slate-100"
              >
                Add "{{ searchTerm }}"
              </li>
            </ul>
          </div>
        </div>
      </fieldset> -->

      <ProductSelector
        :ideasOptions="featureIdeasOptions"
        :disabled="disabled"
        @selected-product="populateFields"
      />
      <div
        v-if="!disabled"
        class="inline-flex items-center justify-center w-full mb-6"
      >
        <hr class="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <span
          class="absolute px-3 font-semibold text-gray-500 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900"
          >or</span
        >
      </div>
      <fieldset
        class="grid grid-cols-2 gap-8 mb-6 border border-gray-200 rounded-lg p-8 relative"
      >
        <div
          v-if="disabled == false"
          class="absolute text-m top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4 text-md text-gray-900 dark:text-white"
        >
          <h3
            class="text-md col-span-2 font-medium text-gray-900 dark:text-white mx-auto"
          >
            Describe your own idea
          </h3>
        </div>

        <div v-for="(data, index) in questionAndAnswers" :key="index">
          <label
            :for="'question-input-' + index"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ data.question }}
          </label>
          <input
            :disabled="disabled"
            :id="'question-input-' + index"
            v-model="data.answer"
            type="text"
            class="bg-slate-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </fieldset>
      <div class="flex justify-center">
        <button
          v-if="disabled == false"
          @click="clearForm"
          type="button"
          class="px-3 py-2 text-sm mr-4 font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-slate-50"
        >
          Clear
        </button>
        <button
          v-if="disabled == false"
          @click="submitForm"
          type="button"
          class="px-3 py-2 text-sm mr-4 font-medium text-center text-white bg-gray-900 border border-gray-300 rounded-lg hover:bg-gray-700"
        >
          Start
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useAuth } from "vue-clerk";
import { useRouter } from "vue-router";

import ProductSelector from "@/components/ProductSelector.vue";
//import PlaybookStepper from "@/components/PlaybookStepper.vue";
import { productIdeas } from "@/utils/productIdeas.js";

export default {
  inject: ["eventBus"],
  props: {
    questionAndAnswers: Array,
    disabled: Boolean,
    projects: Array,
    sessionInfo: Object,
  },
  data() {
    return {
      responses: [],
      selectedIdeaId: "",
      inputFieldMap: {
        "question-input-0": "problem",
        "question-input-1": "idea",
        "question-input-2": "audience",
        "question-input-3": "benefits",
      },
      userId: "",
      searchTerm: "",
      sessionName: "",
      showOptions: false,
    };
  },
  async created() {
    const { userId } = useAuth();
    if (!userId.value) {
      const router = useRouter();
      router.push("/sign-in");
    } else {
      this.userId = userId.value;
    }

    if (this.sessionInfo) {
      this.sessionName = this.sessionInfo.name;
      this.searchTerm = this.sessionInfo.project_name;
    } else {
      console.error("sessionInfo prop is not provided or is null.");
    }
  },
  computed: {
    filteredProjects() {
      return this.projects.filter((project) => {
        return project.name
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());
      });
    },
    featureIdeasOptions() {
      return productIdeas;
    },
  },
  methods: {
    filterProjects() {
      if (this.searchTerm.trim() !== "") {
        this.showOptions = true;
      } else {
        this.showOptions = false;
      }
    },
    closeDropdown() {
      setTimeout(() => {
        this.showOptions = false;
      }, 150);
    },
    handleSelection(project) {
      this.searchTerm = project;
      this.showOptions = false;
    },
    handleNewProject(searchTerm) {
      this.searchTerm = searchTerm;
      this.showOptions = false;
    },
    clearForm() {
      this.questionAndAnswers.forEach((question) => {
        question.answer = "";
      });
    },
    submitForm() {
      sessionStorage.setItem("sessionName", this.sessionName);
      sessionStorage.setItem("projectName", this.searchTerm);
      this.eventBus.emit(
        "initial-questionnaire-response",
        this.questionAndAnswers
      );
    },
    updateQuestionAndAnswers(newVal) {
      this.updatedQuestionsAndAnswers = newVal;
    },
    populateFields(selectedIdeaId) {
      const selectedIdea = productIdeas.find(
        (idea) => idea.id === parseInt(selectedIdeaId)
      );

      if (selectedIdea) {
        this.questionAndAnswers.forEach((question) => {
          question.answer = selectedIdea[question.label];
        });

        // If you are using Vue 2, to make it reactive you might need to use Vue.set or spread syntax
        // this.questionAndAnswers = [...this.questionAndAnswers]

        // Emit an event with the updated questions and answers array
        this.$emit("update:questionAndAnswers", this.questionAndAnswers);
      }
    },
  },
  watch: {
    selectedIdeaId() {
      this.populateFields();
    },
  },
  components: {
    //PlaybookStepper,
    ProductSelector,
  },
};
</script>
