<template>
  <div>
    <div v-if="layerFourData">
      <div class="ml-12">
        <ul class="text-md font-normal text-gray-900">
          <li
            v-for="(item, index) in layerFourData"
            :key="item"
            class="relative w-full rounded-t-lg gap-y-10"
          >
            <!-- bullet -->
            <div class="component-outline-bullet"></div>
            <div class="component-outline-line"></div>

            <div class="flex items-start pl-6">
              <label
                for="default-checkbox"
                class="flex-1 py-2 text-md font-normal text-gray-900"
              >
                <span
                  class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 mr-2 text-xs font-bold text-blue-600 ring-1 ring-inset ring-blue-500/10"
                >
                  {{ layersDisplay[3] }} {{ index + 1 }}
                </span>
                <span class="font-bold">{{ item.title }}</span
                >:
                {{ item.description }}
              </label>
              <font-awesome-icon
                @click="deleteItem(index)"
                :icon="['fas', 'trash-alt']"
                class="mt-3 w-4 h-4 text-red-200 hover:text-red-500 ml-2"
              />
            </div>
          </li>
        </ul>
      </div>
      <div>
        <textarea
          v-if="userWantsToAdd"
          v-model="userInputTitle"
          rows="1"
          class="mt-2 p-2 w-full text-gray-900 bg-slate-100 border border-gray-300 rounded focus:ring-blue-500"
          placeholder="Enter title here"
        ></textarea>
        <textarea
          v-if="userWantsToAdd"
          v-model="userInputDescription"
          rows="2"
          class="mt-2 p-2 w-full text-gray-900 bg-slate-100 border border-gray-300 rounded focus:ring-blue-500"
          placeholder="Enter description here"
        ></textarea>
      </div>
      <div class="ml-6 my-4">
        <button
          @click="addItem()"
          type="button"
          class="px-3 py-2 mr-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          {{ addItemButtonText() }}
        </button>
        <button
          @click="generateMore()"
          type="button"
          class="px-3 py-2 mr-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          Generate more {{ this.layers[3] }}
        </button>
      </div>
    </div>
    <PageLoading v-if="this.isLoading" />
  </div>
</template>

<script>
import { useAuth } from "vue-clerk";
import { useRouter } from "vue-router";
import axios from "axios";
import PageLoading from "@/components/PageLoading.vue";

export default {
  inject: ["eventBus"],
  props: {
    l4LoadType: {
      type: String,
      required: true,
    },
    initialData: {
      type: String,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
    layersDisplay: {
      type: Array,
      required: true,
    },
    chosenPlaybook: {
      type: Object,
      required: true,
    },
    sessionData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      layerFourData: null,
      isLoading: false,
      userInputTitle: "",
      userInputDescription: "",
      userWantsToAdd: false,
      userId: "",
    };
  },
  components: {
    PageLoading,
  },
  created() {
    const { userId } = useAuth();
    if (!userId.value) {
      const router = useRouter();
      router.push("/sign-in");
    } else {
      this.userId = userId.value;
    }
  },
  mounted() {
    if (this.l4LoadType === "retrieve") {
      if (this.sessionData !== null && this.sessionData[this.layers[0]]) {
        this.sessionData[this.layers[0]].forEach((layerOneContent) => {
          if (layerOneContent[this.layers[1]]) {
            layerOneContent[this.layers[1]].forEach((layerTwoContent) => {
              if (layerTwoContent[this.layers[2]]) {
                layerTwoContent[this.layers[2]].forEach((layerThreeContent) => {
                  const layerThreeText =
                    layerThreeContent.title + layerThreeContent.description;
                  if (
                    layerThreeText === this.initialData &&
                    layerThreeContent[this.layers[3]]
                  ) {
                    this.layerFourData = layerThreeContent[this.layers[3]];
                  }
                });
              }
            });
          }
        });
      }
    } else if (this.l4LoadType === "generate") {
      this.generateContent(this.layers[3], this.initialData, false);
    }
  },
  watch: {
    layerFourData(data) {
      this.storeData(this.initialData, data);
      this.updateS3Data();
    },
  },
  methods: {
    getAllLayerFourData() {
      let sessionData =
        JSON.parse(sessionStorage.getItem("sessionData"))["data"] || {};
      let layerData = [];

      if (sessionData !== null && sessionData[this.layers[0]]) {
        sessionData[this.layers[0]].forEach((layerOneContent) => {
          if (layerOneContent[this.layers[1]]) {
            layerOneContent[this.layers[1]].forEach((layerTwoContent) => {
              if (layerTwoContent[this.layers[2]]) {
                layerTwoContent[this.layers[2]].forEach((layerThreeContent) => {
                  if (layerThreeContent[this.layers[3]] !== undefined) {
                    layerData.push(...layerThreeContent[this.layers[3]]);
                  }
                });
              }
            });
          }
        });
        return layerData;
      }
    },
    generateMore() {
      this.generateContent(this.layers[3], this.initialData, true);
    },
    storeData(previousLayerData, currentLayerData) {
      try {
        const storedData =
          JSON.parse(sessionStorage.getItem("threadData")) || {};

        for (const layerOneData of storedData[this.layers[0]]) {
          for (const layerTwoData of layerOneData[this.layers[1]]) {
            if (Array.isArray(layerTwoData[this.layers[2]])) {
              const matchedContent = layerTwoData[this.layers[2]].find(
                (item) => {
                  return item.title + item.description === previousLayerData;
                }
              );

              if (matchedContent) {
                matchedContent[this.layers[3]] = currentLayerData;
                sessionStorage.setItem(
                  "threadData",
                  JSON.stringify(storedData)
                );
                return;
              }
            }
          }
        }

        console.log(
          `Content match not found: ${previousLayerData}, ${currentLayerData}`
        );
      } catch (error) {
        console.error("Error while storing data in sessionStorage:", error);
      }
    },
    async updateS3Data() {
      try {
        let newData = {
          session_id: this.$route.query.id,
          chosen_playbook: this.chosenPlaybook,
          initial_questionnaire: JSON.parse(
            sessionStorage.getItem("questionnaireResponse")
          ),
        };
        sessionStorage.setItem("sessionData", JSON.stringify(newData));
      } catch (error) {
        console.error("Error while storing data in sessionStorage:", error);
      }

      try {
        let existingData = JSON.parse(sessionStorage.getItem("sessionData"));
        let newData = {
          ...existingData,
          data: JSON.parse(sessionStorage.getItem("threadData")),
        };
        sessionStorage.setItem("sessionData", JSON.stringify(newData));
      } catch (error) {
        console.error("Error while storing data in sessionStorage:", error);
      }

      try {
        await axios.post(
          "pcp-api/s3-contents",
          {
            bucket_name: process.env.VUE_APP_SESSIONS_DATA,
            object_key: this.userId + "/" + this.$route.query.id,
            data: JSON.parse(sessionStorage.getItem("sessionData")) || {},
          },
          {
            headers: {
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    deleteItem(index) {
      const updatedData = [
        ...this.layerFourData.slice(0, index),
        ...this.layerFourData.slice(index + 1),
      ];
      this.layerFourData = updatedData;
    },
    addItemButtonText() {
      if (this.userWantsToAdd) {
        return "Save";
      } else {
        return "Add " + this.layersDisplay[3];
      }
    },
    addItem() {
      this.userWantsToAdd = true;
      if (this.userInputDescription && this.userInputTitle) {
        this.layerFourData = [
          ...this.layerFourData,
          {
            title: this.userInputTitle,
            description: this.userInputDescription,
          },
        ];
        this.userInputTitle = "";
        this.userInputDescription = "";
        this.userWantsToAdd = false;
      }
    },
    async generateContent(layerName, userInput, generateMore) {
      this.isLoading = true;
      try {
        const response = await axios.post(
          "pcp-api/thread-response",
          {
            bucket_name: process.env.VUE_APP_PLAYBOOKS_SOURCE,
            playbook_key: this.chosenPlaybook.s3_file_key,
            layer_name: layerName,
            user_input: userInput,
            generate_more: generateMore,
            exclude_content: generateMore ? this.getAllLayerFourData() : "",
          },
          {
            headers: {
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        this.isLoading = false;
        if (generateMore) {
          this.layerFourData = [
            ...this.layerFourData,
            ...response.data[layerName],
          ];
        } else {
          this.layerFourData = response.data[layerName];
        }
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
  },
};
</script>
