<template>
  <navbar>
    <div
      class="mx-auto mb-6 max-w-screen-xl relative flex h-32 items-center justify-between"
    >
      <PageLoading class="mx-8 pl-20" v-if="this.isLoading" />
      <div class="ml-2 inline-flex rounded-md shadow-sm" role="group">
        <button
          v-for="(option, index) in allPlaybooks"
          :key="index"
          type="button"
          class="px-4 py-2 text-sm font-medium"
          :class="[
            index === 0 ? 'rounded-l-lg' : '',
            index === allPlaybooks.length - 1 ? 'rounded-r-md' : '',
            selectedOption === option
              ? 'bg-gray-900 text-gray-100'
              : 'bg-slate-100 text-gray-900',
          ]"
          @click="selectOption(option)"
        >
          {{ option.name }}
        </button>
      </div>
    </div>
  </navbar>
</template>

<script>
import playbooksList from "@/utils/playbooksList.json";
import PageLoading from "@/components/PageLoading.vue";

export default {
  inject: ["eventBus"],
  data() {
    return {
      allPlaybooks: [],
      selectedOption: "",
      isLoading: false,
    };
  },
  components: {
    PageLoading,
  },
  created() {
    this.allPlaybooks = playbooksList;
    this.selectOption(this.allPlaybooks[0]);
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.eventBus.emit("user-chosen-playbook", this.selectedOption);
    },
  },
};
</script>
