<template>
  <div class="mx-auto">
    <div>
      <HeaderLogo class="lg:mx-32 md:mx-16 my-1" />
      <hr />
    </div>
    <section class="w-5/6 justify-center items-center mx-auto">
      <div class="px-4">
        <div class="py-8 flex justify-between items-center mx-auto relative">
          <h2 class="text-xl tracking-tight font-extrabold text-gray-900">
            All Projects
          </h2>
          <div class="relative">
            <button
              @click="openModal"
              class="bg-slate-50 border border-gray-300 text-gray-900 hover:bg-slate-100 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
            >
              Create Project
            </button>
          </div>
        </div>
        <div v-if="!isLoading">
          <div v-if="userProjects.length < 0" class="grid gap-6 grid-cols-3">
            <a
              v-for="(item, index) in userProjects"
              :key="index"
              :href="getSessionUrl(item.session_id)"
            >
              <article
                class="p-6 rounded-xl border border-gray-200 shadow-sm hover:bg-slate-50"
              >
                <h2
                  class="mb-3 text-lg font-medium tracking-tight text-gray-900"
                >
                  {{ item.name }}
                </h2>
                <span class="text-sm">{{ timeAgo(item.created_on) }}</span>
              </article>
            </a>
          </div>
          <div v-else>No projects found</div>
        </div>
      </div>
    </section>
    <CreateProjectModal
      :isOpen="isModalOpen"
      @close="closeModal"
      @project-created="handleProjectCreated"
    />
  </div>
</template>

<script>
import { useAuth } from "vue-clerk";
import { useRouter } from "vue-router";
import axios from "axios";

import HeaderLogo from "@/components/HeaderLogo.vue";
import CreateProjectModal from "@/components/CreateProjectModal.vue";

export default {
  data() {
    return {
      isLoading: false,
      userId: "",
      userSessions: [],
      userProjects: [],
      filteredUserSessions: [],
      isDropdownVisible: false,
      filterBy: "",
      isModalOpen: false,
    };
  },
  components: {
    HeaderLogo,
    CreateProjectModal,
  },
  mounted() {
    const { isSignedIn, userId } = useAuth();
    const router = useRouter();
    if (!isSignedIn.value) {
      router.push("/sign-in");
    } else {
      this.userId = userId.value;
    }
    this.getAllProjects();
    this.getAllSessions();
  },
  methods: {
    getSessionUrl(sessionId) {
      return `/session?id=${sessionId}`;
    },
    async getAllProjects() {
      this.isLoading = true;
      try {
        const response = await axios.get("pcp-api/utility", {
          params: {
            user_id: this.userId,
            type: "user.projects",
          },
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });
        this.userProjects = response.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllSessions() {
      this.isLoading = true;
      try {
        const response = await axios.get("pcp-api/utility", {
          params: {
            user_id: this.userId,
            type: "user.sessions",
          },
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });
        this.userSessions = response.data;
        this.filteredUserSessions = this.userSessions;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    timeAgo(timestamp) {
      const currentDate = new Date();
      const inputDate = new Date(timestamp);
      const timeDifference = currentDate - inputDate;

      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);

      if (months > 0) {
        return `${months} month${months !== 1 ? "s" : ""} ago`;
      } else if (days > 0) {
        return `${days} day${days !== 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
      } else {
        return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async handleProjectCreated(newProject) {
      try {
        // Add the new project to the list
        this.userProjects.push(newProject);
        // Optionally, refresh the entire list
        await this.getAllProjects();
        // Close the modal
        this.closeModal();
      } catch (error) {
        console.error("Error handling new project:", error);
      }
    },
    async createProject(projectData) {
      try {
        const response = await axios.post("pcp-api/projects", projectData, {
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });
        return response.data;
      } catch (error) {
        console.error("Error creating project:", error);
        throw error;
      }
    },
  },
};
</script>
