<template>
  <div>
    <div v-if="layerOneData">
      <div>
        <ul class="text-md font-normal text-gray-900">
          <li
            v-for="(item, index) in layerOneData"
            :key="item"
            :id="layersDisplay[0].toLowerCase() + '-' + (index + 1)"
            class="relative w-full rounded-t-lg h-full"
          >
            <!-- bullet -->
            <div class="component-outline-bullet"></div>
            <div class="component-outline-line"></div>

            <div class="flex items-start py-2 pl-5">
              <label
                for="default-checkbox"
                class="flex-1 py-2 text-md font-normal text-gray-900"
              >
                <span
                  class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 mr-2 text-xs font-bold text-purple-600 ring-1 ring-inset ring-purple-500/10"
                >
                  {{ layersDisplay[0] }} {{ index + 1 }}
                </span>
                <span class="font-bold">{{ item.title }}</span> -
                {{ item.description }}
              </label>
              <font-awesome-icon
                @click="deleteItem(index)"
                :icon="['fas', 'trash-alt']"
                class="mt-3 w-4 h-4 text-red-200 hover:text-red-500 ml-2"
              />
            </div>
            <LayerTwo
              v-if="showNext"
              :l2-load-type="this.l2LoadType"
              :initial-data="item.title + item.description"
              :session-data="this.sessionData"
              :layers="this.layers"
              :layers-display="this.layersDisplay"
              :chosen-playbook="this.chosenPlaybook"
            />
          </li>
        </ul>
      </div>
      <div>
        <textarea
          v-if="userWantsToAdd"
          v-model="userInputTitle"
          rows="1"
          class="mt-2 p-2 w-full text-gray-900 bg-slate-100 border border-gray-300 rounded focus:ring-blue-500"
          placeholder="Enter title here"
        ></textarea>
        <textarea
          v-if="userWantsToAdd"
          v-model="userInputDescription"
          rows="2"
          class="mt-2 p-2 w-full text-gray-900 bg-slate-100 border border-gray-300 rounded focus:ring-blue-500"
          placeholder="Enter description here"
        ></textarea>
      </div>
      <div class="my-4">
        <button
          @click="addItem()"
          type="button"
          class="px-3 py-2 mr-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          {{ addItemButtonText() }}
        </button>
        <button
          @click="generateMore()"
          type="button"
          class="px-3 py-2 mr-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          Generate more {{ this.layers[0] }}
        </button>
        <button
          @click="generateNext()"
          type="button"
          class="px-4 py-2 text-sm mr-4 font-semibold text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-slate-50 focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          > Next: Generate {{ this.layers[1] }}
        </button>
      </div>
    </div>
    <PageLoading v-if="this.isLoading" />
  </div>
</template>

<script>
import { useAuth } from "vue-clerk";
import { useRouter } from "vue-router";
import axios from "axios";
import PageLoading from "@/components/PageLoading.vue";
import LayerTwo from "@/components/FourLayered/LayerTwo.vue";

export default {
  inject: ["eventBus"],
  props: {
    l1LoadType: {
      type: String,
      required: true,
    },
    initialData: {
      type: String,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
    layersDisplay: {
      type: Array,
      required: true,
    },
    chosenPlaybook: {
      type: Object,
      required: true,
    },
    sessionData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showNext: false,
      layerOneData: null,
      isLoading: false,
      userInputTitle: "",
      userInputDescription: "",
      userWantsToAdd: false,
      l2LoadType: "",
      userId: "",
    };
  },
  components: {
    PageLoading,
    LayerTwo,
  },
  created() {
    const { userId } = useAuth();
    if (!userId.value) {
      const router = useRouter();
      router.push("/sign-in");
    } else {
      this.userId = userId.value;
    }
  },
  mounted() {
    if (this.l1LoadType === "retrieve") {
      if (this.sessionData !== null) {
        this.layerOneData = this.sessionData[this.layers[0]];

        for (const element of this.layerOneData) {
          if (element[this.layers[1]]) {
            this.l2LoadType = "retrieve";
            this.showNext = true;
            break;
          }
        }
      }
    } else if (this.l1LoadType === "generate") {
      this.generateContent(this.layers[0], this.initialData, false);
    }
  },
  watch: {
    layerOneData(data) {
      this.storeData(data);
      this.updateS3Data();
    },
  },
  methods: {
    generateMore() {
      this.generateContent(this.layers[0], this.initialData, true);
    },
    storeData(currentLayerData) {
      try {
        let existingData =
          JSON.parse(sessionStorage.getItem("threadData")) || {};
        let newData = {
          ...existingData,
          [this.layers[0]]: [...currentLayerData],
        };
        sessionStorage.setItem("threadData", JSON.stringify(newData));
      } catch (error) {
        console.error("Error while storing data in sessionStorage:", error);
      }
    },
    async updateS3Data() {
      try {
        let newData = {
          session_id: this.$route.query.id,
          chosen_playbook: this.chosenPlaybook,
          initial_questionnaire: JSON.parse(
            sessionStorage.getItem("questionnaireResponse")
          ),
        };
        sessionStorage.setItem("sessionData", JSON.stringify(newData));
      } catch (error) {
        console.error("Error while storing data in sessionStorage:", error);
      }

      try {
        let existingData = JSON.parse(sessionStorage.getItem("sessionData"));
        let newData = {
          ...existingData,
          data: JSON.parse(sessionStorage.getItem("threadData")),
        };
        sessionStorage.setItem("sessionData", JSON.stringify(newData));
      } catch (error) {
        console.error("Error while storing data in sessionStorage:", error);
      }

      try {
        await axios.post(
          "pcp-api/s3-contents",
          {
            bucket_name: process.env.VUE_APP_SESSIONS_DATA,
            object_key: this.userId + "/" + this.$route.query.id,
            data: JSON.parse(sessionStorage.getItem("sessionData")) || {},
          },
          {
            headers: {
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    deleteItem(index) {
      const updatedData = [
        ...this.layerOneData.slice(0, index),
        ...this.layerOneData.slice(index + 1),
      ];
      this.layerOneData = updatedData;
    },
    addItemButtonText() {
      if (this.userWantsToAdd) {
        return "Save";
      } else {
        return "Add " + this.layersDisplay[0];
      }
    },
    generateNext() {
      this.l2LoadType = "generate";
      this.showNext = true;
    },
    addItem() {
      this.userWantsToAdd = true;
      if (this.userInputTitle && this.userInputDescription) {
        this.layerOneData = [
          ...this.layerOneData,
          {
            title: this.userInputTitle,
            description: this.userInputDescription,
          },
        ];
        this.userInputTitle = "";
        this.userInputDescription = "";
        this.userWantsToAdd = false;
      }
    },
    async generateContent(layerName, userInput, generateMore) {
      this.isLoading = true;
      try {
        const response = await axios.post(
          "pcp-api/thread-response",
          {
            bucket_name: process.env.VUE_APP_PLAYBOOKS_SOURCE,
            playbook_key: this.chosenPlaybook.s3_file_key,
            layer_name: layerName,
            user_input: userInput,
            generate_more: generateMore,
            exclude_content: this.layerOneData,
          },
          {
            headers: {
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        this.isLoading = false;
        if (generateMore) {
          this.layerOneData = [
            ...this.layerOneData,
            ...response.data[layerName],
          ];
        } else {
          this.layerOneData = response.data[layerName];
        }
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
  },
};
</script>
