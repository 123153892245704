<template>
  <div class="pt-2 flex items-center justify-between max-auto">
    <h1
      @click="redirectToHomePage"
      class="mb-2 text-lg font-black tracking-tighter text-gray-900 dark:text-white md:text-xl lg:text-2xl cursor-pointer"
    >
      Product Copilot
      <span
        class="bg-gray-100 text-gray-800 tracking-normal text-xs font-medium px-2.5 py-0.5 rounded"
        >Alpha {{ appVersion }}</span
      >
    </h1>
    <span class="flex items-center justify-center" v-if="isSignedIn">
      <!-- <div class="relative inline-block text-left">
        <button
          @click="toggleDropdown"
          type="button"
          class="inline-flex justify-center items-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="options-menu"
          aria-haspopup="true"
          :aria-expanded="isOpen"
        >
          {{ currentProjectName }}
          <svg
            class="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div
          v-if="isOpen"
          class="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div class="py-1" role="none">
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              @click="changeProject"
              >Change project</a
            >
            <a
              href="#"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              @click="manageProjects"
              >Manage projects</a
            >
          </div>
        </div>
      </div> -->
      <font-awesome-icon
        icon="history"
        class="w-4 h-4 px-6 text-gray-500 hover:text-gray-700 ml-2"
        @click="goToPreviousSession"
      />
      <UserButton afterSignOutUrl="/sign-in" />
    </span>
  </div>
</template>

<script>
import { UserButton, useUser } from "vue-clerk";
import packageInfo from "@/../package.json";
import { watch } from "vue";

export default {
  components: {
    UserButton,
  },
  setup() {
    const { user, isSignedIn } = useUser(); // Use the useUser composable

    // Watch the user object for changes and update the global variable
    watch(
      user,
      (newUser) => {
        window.globalUserData = newUser; // Set user data to a global variable
      },
      {
        immediate: true, // Trigger the handler immediately with the current value
        deep: true, // Ensure to react to nested property changes inside the user object
      }
    );

    return {
      user,
      isSignedIn,
    };
  },
  data() {
    return {
      appVersion: packageInfo.version,
      isOpen: false,
    };
  },
  methods: {
    goToPreviousSession() {
      this.$router.push("/previous-sessions");
    },
    redirectToHomePage() {
      this.$router.push("/");
    },
    // toggleDropdown() {
    //   this.isOpen = !this.isOpen;
    // },
    // changeProject() {
    //   // Implement change project logic
    //   console.log("Changing project");
    //   this.isOpen = false;
    // },
    // manageProjects() {
    //   console.log("Managing projects");
    //   this.$router.push("/projects");
    //   this.isOpen = false;
    // },
  },
};
</script>
