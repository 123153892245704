<template>
  <div class="mx-auto">
    <div>
      <HeaderLogo class="lg:mx-32 md:mx-16 my-1" />
      <hr />
    </div>
    <section class="w-5/6 justify-center items-center mx-auto">
      <div class="px-4">
        <div class="py-8 flex justify-between items-center mx-auto relative">
          <h2 class="text-xl tracking-tight font-extrabold text-gray-900">
            Previous Sessions
          </h2>
          <!-- Commented out filter dropdown
          <div class="relative">
            <button
              id="dropdownDefaultButton"
              ref="dropdownDefaultButton"
              @click="toggleDropdown"
              class="bg-slate-50 border border-gray-300 text-gray-900 hover:bg-slate-100 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
              type="button"
            >
              {{ filterBy || "Filter by Projects" }}
              <svg
                class="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <div
              id="dropdown"
              ref="dropdown"
              v-show="isDropdownVisible"
              class="absolute border border-gray-200 right-0 mt-2 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul
                class="text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <div>
                  <a
                    href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    @click="selectOption('', 'Show All')"
                    >Show All</a
                  >
                  <hr />
                </div>

                <li v-for="project in userProjects" :key="project.project_id">
                  <a
                    href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    @click="selectOption(project.project_id, project.name)"
                    >{{ project.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          -->
        </div>
        <div v-if="!isLoading">
          <div
            v-if="filteredUserSessions.length > 0"
            class="grid gap-6 grid-cols-3"
          >
            <a
              v-for="(item, index) in filteredUserSessions"
              :key="index"
              :href="getSessionUrl(item.session_id)"
            >
              <article
                class="p-6 rounded-xl border border-gray-200 shadow-sm hover:bg-slate-50"
              >
                <div
                  class="flex justify-between items-center mb-4 text-gray-500"
                >
                  <div class="flex items-center">
                    <span
                      class="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                      >{{ item.playbook_name }}</span
                    >
                    <!-- <span
                      class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                      >{{ item.project_name }}</span
                    > -->
                  </div>
                </div>

                <h2
                  class="mb-3 text-lg font-medium tracking-tight text-gray-900"
                >
                  {{ item.session_name }}
                </h2>
                <span class="text-sm">{{ timeAgo(item.created_on) }}</span>
              </article>
            </a>
          </div>
          <div v-else>No sessions found</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useAuth } from "vue-clerk";
import { useRouter } from "vue-router";
import axios from "axios";

import HeaderLogo from "@/components/HeaderLogo.vue";
// import PageLoading from "@/components/PageLoading.vue";

export default {
  data() {
    return {
      isLoading: false,
      userId: "",
      userSessions: [],
      // userProjects: [], // Commented out
      filteredUserSessions: [],
      // isDropdownVisible: false, // Commented out
      // filterBy: "", // Commented out
    };
  },
  components: {
    HeaderLogo,
    // PageLoading,
  },
  mounted() {
    const { isSignedIn, userId } = useAuth();
    const router = useRouter();
    if (!isSignedIn.value) {
      router.push("/sign-in");
    } else {
      this.userId = userId.value;
    }
    // document.addEventListener("click", this.handleClickOutside); // Commented out
    // this.getAllProjects(); // Commented out
    this.getAllSessions();
  },
  methods: {
    getSessionUrl(sessionId) {
      return `/session?id=${sessionId}`;
    },
    // Commented out getAllProjects method
    /*
    async getAllProjects() {
      this.isLoading = true;
      try {
        const response = await axios.get("pcp-api/utility", {
          params: {
            user_id: this.userId,
            type: "user.projects",
          },
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });
        this.userProjects = response.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    */
    async getAllSessions() {
      this.isLoading = true;
      try {
        const response = await axios.get("pcp-api/utility", {
          params: {
            user_id: this.userId,
            type: "user.sessions",
          },
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });
        this.userSessions = response.data;
        this.filteredUserSessions = this.userSessions
          .slice()
          .sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching sessions:", error);
        // Optionally, you can set an error state or show a notification to the user
      }
    },
    timeAgo(timestamp) {
      const currentDate = new Date();
      const inputDate = new Date(timestamp);
      const timeDifference = currentDate - inputDate;

      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);

      if (months > 0) {
        return `${months} month${months !== 1 ? "s" : ""} ago`;
      } else if (days > 0) {
        return `${days} day${days !== 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
      } else {
        return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
      }
    },
    // Commented out filter-related methods
    /*
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    selectOption(project_id, project_name) {
      if (project_id) {
        this.filterBy = project_name;
        this.filterProjects(project_id);
      } else {
        this.filterBy = "Show All";
        this.filteredUserSessions = this.userSessions
          .slice()
          .sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
      }
      this.isDropdownVisible = false;
    },
    filterProjects(project_id) {
      console.log(`Filtering projects by: ${project_id}`);
      this.filteredUserSessions = this.userSessions
        .filter((session) => session.project_id === project_id)
        .sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
      console.log(this.filteredUserSessions);
    },
    handleClickOutside(event) {
      const dropdown = this.$refs.dropdown;
      const button = this.$refs.dropdownDefaultButton;
      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        !button.contains(event.target)
      ) {
        this.isDropdownVisible = false;
      }
    },
    */
  },
  // beforeUnmount() {
  //   document.removeEventListener("click", this.handleClickOutside);
  // },
};
</script>
