import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./index.css";

import { inject } from "@vercel/analytics";
import { clerkPlugin } from "vue-clerk/plugin";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
library.add(far);

import mitt from "mitt";
const emitter = mitt();

// vercel analytics
inject();

createApp(App)
  .provide("eventBus", emitter)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(clerkPlugin, {
    publishableKey: process.env.VUE_APP_CLERK_PUBLISHABLE_KEY,
  })
  .use(router)
  .mount("#app");
