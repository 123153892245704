import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SessionView from "../views/SessionView.vue";
import PreviousSessions from "../views/PreviousSessions.vue";
import SignIn from "../views/Auth/SignIn.vue";
import SignUp from "../views/Auth/SignUp.vue";
import Projects from "../views/AllProjects.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/sign-in",
    name: "signin",
    component: SignIn,
    children: [
      { path: "factor-one", component: SignIn },
      { path: "factor-two", component: SignIn },
      { path: "reset-password", component: SignIn },
      { path: "reset-password-success", component: SignIn },
      { path: "sso-callback", component: SignIn },
      { path: "choose", component: SignIn },
      { path: "verify", component: SignIn },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: SignUp,
    children: [
      { path: "verify-email-address", component: SignUp },
      { path: "verify-phone-number", component: SignUp },
      {
        path: "continue",
        component: SignUp,
        children: [
          { path: "verify-email-address", component: SignUp },
          { path: "verify-phone-number", component: SignUp },
        ],
      },
      { path: "sso-callback", component: SignUp },
      { path: "verify", component: SignUp },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/session",
    name: "session",
    component: SessionView,
    meta: { requiresAuth: true },
  },
  {
    path: "/previous-sessions",
    name: "previous-sessions",
    component: PreviousSessions,
    meta: { requiresAuth: true },
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
