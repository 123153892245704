<template>
  <div class="flex flex-col min-h-screen">
    <div>
      <HeaderLogo class="lg:mx-32 md:mx-16 my-1" />
      <hr />
    </div>
    <div class="flex flex-1">
      <div class="w-4/5 mt-8 justify-center items-center mx-auto">
        <div v-if="userId && sessionType && playbookInfo">
          <InitialQuestionnaire
            :questionAndAnswers="initialQuestionnaire"
            :sessionInfo="sessionInfo"
            :disabled="true"
          />
          <ThreadView
            :session-id="sessionId"
            :session-type="sessionType"
            :session-data="sessionDetails"
            :initial-data="questionnaireResponse"
            :chosen-playbook="chosenPlaybook"
            :layer-data="playbookInfo"
          />
          <div class="w-5/6 justify-center items-center mx-auto my-4">
            <button
              @click="copyToClipboard"
              type="button"
              class="px-3 py-2 mr-4 text-sm font-medium text-center text-white bg-gray-800 border border-gray-300 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Copy to Clipboard
            </button>
          </div>
        </div>
        <div class="w-5/6 justify-center items-center mx-auto my-4" v-else>
          <PageLoading />
        </div>
      </div>
      <div class="w-1/5 h-full mr-12 my-8">
        <deep-chat
          style="position: fixed; height: 80vh; border-radius: 15px"
          :initialMessages="initialMessages"
          :request="inputRequest"
        ></deep-chat>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "deep-chat";
import { useAuth } from "vue-clerk";
import { useRouter } from "vue-router";
import PageLoading from "@/components/PageLoading.vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
import ThreadView from "./ThreadView.vue";
import InitialQuestionnaire from "@/components/InitialQuestionnaire.vue";

export default {
  inject: ["eventBus"],
  components: {
    PageLoading,
    HeaderLogo,
    InitialQuestionnaire,
    ThreadView,
  },
  data() {
    return {
      sessionType: "",
      chosenPlaybook: null,
      playbookInfo: null,
      sessionId: "",
      isLoading: false,
      sessionDetails: null,
      initialQuestionnaire: null,
      questionnaireResponse: "",
      userId: "",
      sessionInfo: {
        name: "",
        projectName: "",
      },
      initialMessages: [],
    };
  },
  computed: {
    inputRequest() {
      return {
        url: "/pcp-api/chat",
        method: "POST",
        headers: {
          "x-api-key": process.env.VUE_APP_API_KEY,
        },
      };
    },
  },
  created() {
    const { userId } = useAuth();
    if (!userId.value) {
      const router = useRouter();
      router.push("/sign-in");
    } else {
      this.userId = userId.value;
      this.sessionId = this.$route.query.id;
      this.getSessionDetails();
    }
  },
  methods: {
    async getSessionDetails() {
      this.isLoading = true;
      try {
        const s3ContentsPromise = axios.get("pcp-api/s3-contents", {
          params: {
            bucket_name: process.env.VUE_APP_SESSIONS_DATA,
            object_key: `${this.userId}/${this.sessionId}`,
          },
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });

        const sessionDetailsPromise = axios.get("pcp-api/utility", {
          params: {
            session_id: this.sessionId,
            type: "user.session",
          },
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });

        const [s3ContentsResponse, sessionDetailsResponse] = await Promise.all([
          s3ContentsPromise,
          sessionDetailsPromise,
        ]);

        const s3ContentsData = s3ContentsResponse.data;

        this.sessionInfo.name = sessionDetailsResponse.data[0]["session_name"];
        this.sessionInfo.project_name =
          sessionDetailsResponse.data[0]["project_name"];

        this.sessionType = "retrival";
        this.initialQuestionnaire =
          s3ContentsResponse.data["initial_questionnaire"];
        sessionStorage.setItem(
          "questionnaireResponse",
          JSON.stringify(this.initialQuestionnaire)
        );
        this.chosenPlaybook = s3ContentsResponse.data["chosen_playbook"];
        this.addSessionData(
          this.sessionId,
          this.chosenPlaybook,
          this.initialQuestionnaire
        );
        this.sessionDetails = s3ContentsData["data"];
        await this.getPlaybookInformation(this.chosenPlaybook.s3_file_key);
        this.isLoading = false;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          if (error.response.data == "File not found in the S3 bucket") {
            this.sessionType = "new";
            this.chosenPlaybook = JSON.parse(
              sessionStorage.getItem("chosenPlaybook")
            );
            this.initialQuestionnaire = JSON.parse(
              sessionStorage.getItem("questionnaireResponse")
            );
            this.questionnaireResponse = this.formatQuestionnaireResponse(
              this.initialQuestionnaire
            );
            await this.createSession(
              sessionStorage.getItem("sessionName"),
              sessionStorage.getItem("projectName")
            );
            await this.getPlaybookInformation(this.chosenPlaybook.s3_file_key);
            await this.createS3file();
          }
        } else {
          window.alert("Failed to load session");
        }
        this.isLoading = false;
      }
    },
    addSessionData(sessionId, chosenPlaybook, initialQuestionnaire) {
      try {
        let newData = {
          session_id: sessionId,
          chosen_playbook: chosenPlaybook,
          initial_questionnaire: initialQuestionnaire,
        };
        sessionStorage.setItem("sessionData", JSON.stringify(newData));
      } catch (error) {
        console.error("Error while storing data in sessionStorage:", error);
      }
    },
    async createSession(sessionName, projectName) {
      this.sessionInfo.name = sessionName;
      this.sessionInfo.project_name = projectName;

      try {
        await axios.post(
          "pcp-api/utility",
          {
            user_id: this.userId,
            type: "user.sessions",
            data: {
              session_id: this.sessionId,
              session_name: sessionName,
              project_name: projectName || "others",
              playbook_name: this.chosenPlaybook.name,
            },
          },
          {
            headers: {
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    async createS3file() {
      try {
        let newData = {
          session_id: this.sessionId,
          chosen_playbook: this.chosenPlaybook,
          initial_questionnaire: this.initialQuestionnaire,
        };
        sessionStorage.setItem("sessionData", JSON.stringify(newData));
      } catch (error) {
        console.error("Error while storing data in sessionStorage:", error);
      }

      try {
        await axios.post(
          "pcp-api/s3-contents",
          {
            bucket_name: process.env.VUE_APP_SESSIONS_DATA,
            object_key: this.userId + "/" + this.sessionId,
            data: JSON.parse(sessionStorage.getItem("sessionData")) || {},
          },
          {
            headers: {
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    async getPlaybookInformation(playbook_file_key) {
      this.isLoading = true;
      try {
        const response = await axios.get("pcp-api/s3-contents", {
          params: {
            bucket_name: process.env.VUE_APP_PLAYBOOKS_SOURCE,
            object_key: playbook_file_key,
          },
          headers: {
            "x-api-key": process.env.VUE_APP_API_KEY,
          },
        });

        this.playbookInfo = response.data;
        this.isLoading = false;
      } catch (error) {
        window.alert("Failed to retrive playbook information");
      }
    },
    formatQuestionnaireResponse(data) {
      let result = "";
      for (let i = 0; i < data.length; i++) {
        const { question, answer } = data[i];
        if (answer.trim() !== "") {
          result += `${question} - ${answer}`;
        }
      }
      return result;
    },
    copyToClipboard() {
      const storedData =
        JSON.parse(sessionStorage.getItem("sessionData")) || {};
      const data = storedData.data || {};
      const formattedText = this.formatDataHierarchy(data, 0);

      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          alert("Content copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          alert("Failed to copy content to clipboard. Please try again.");
        });
    },
    formatDataHierarchy(data, level) {
      let formattedText = "";
      for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
          formattedText += this.formatArrayItems(key, value, level);
        } else if (typeof value === "object" && value !== null) {
          formattedText += `${this.getBullet(
            level
          )}${this.capitalizeFirstLetter(key)}:\n`;
          formattedText += this.formatDataHierarchy(value, level + 1);
        } else {
          formattedText += `${this.getBullet(
            level
          )}${this.capitalizeFirstLetter(key)}: ${value}\n`;
        }
      }
      return formattedText;
    },
    formatArrayItems(key, items, level) {
      let formattedText = `${this.getBullet(level)}${this.capitalizeFirstLetter(
        key
      )}:\n`;
      items.forEach((item, index) => {
        formattedText += `${this.getBullet(
          level + 1
        )}${this.capitalizeFirstLetter(key)} ${index + 1}:\n`;
        formattedText += this.formatDataHierarchy(item, level + 2);
      });
      return formattedText;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getBullet(level) {
      return "  ".repeat(level) + "• ";
    },
  },
};
</script>
